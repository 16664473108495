import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { IndexComponent } from './index/index.component';
import { MeinTrainingComponent } from './mein-training/mein-training.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LayoutModule } from '@angular/cdk/layout';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { TabStripModule } from '@progress/kendo-angular-layout';

@NgModule({
  declarations: [IndexComponent, MeinTrainingComponent], 
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatIconModule,
    MatSelectModule,
    MatSidenavModule,
    LayoutModule,
    FuseSharedModule,
    FuseSidebarModule,
    TabStripModule
    
  ],
  providers:[DatePipe
  ]
})
export class DashboardModule { }
