import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingsplaeneComponent } from './trainingsplaene.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './details/details.component';
import { TrainingComponent } from './training/training.component';



const routes: Routes = [
  {
    path        : 'training/plans',
    component: TrainingsplaeneComponent,
   // canActivate: [UserGuard, AdminGuard]
},{
  path        : 'training/plan/:id',
  component: DetailsComponent,
 // canActivate: [UserGuard, AdminGuard]
},{
  path        : 'training/:id',
  component: TrainingComponent,
 // canActivate: [UserGuard, AdminGuard]
},{
  path     : 'training/:id/:tpId',
  component: TrainingComponent
  
},{
  path     : 'training/',
  redirectTo: 'training/plans',
}
];

@NgModule({
  imports: [FuseSharedModule,RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrainingsplaeneRoutingModule { }
