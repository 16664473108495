import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TrainingplansService } from 'ttc-api-service';

@Component({
  selector: 'ttc-trainingsplaene',
  templateUrl: './trainingsplaene.component.html',
  styleUrls: ['./trainingsplaene.component.scss']
})
export class TrainingsplaeneComponent implements OnInit {

  categories: any[];
  courses: any[];
  coursesFilteredByCategory: any[];
  filteredCourses: any[];

  categoriesSubscription: Subscription;
  coursesSubscription: Subscription;

  currentCategory = 'ttv';
  searchTerm = '';
  
  constructor(private plaeneService:   TrainingplansService
    ) { }

  ngOnInit(): void {
   // this.coursesSubscription =
      let query = this.plaeneService.entities()
      query.get().subscribe(([data,odata]) => {
            this.filteredCourses = this.coursesFilteredByCategory = this.courses = data;
        });
  }

  filterCoursesByCategory()
  {
      // Filter
      if ( this.currentCategory === 'tts' )
      {
         // this.coursesFilteredByCategory = this.courses;
        //  this.filteredCourses = this.courses;
        this.coursesFilteredByCategory = this.courses.filter((course) => {
            return course.IsForTTSpieler === true;
        });

        this.filteredCourses = [...this.coursesFilteredByCategory];
      }
     else if ( this.currentCategory === 'ttv' )
      {
         // this.coursesFilteredByCategory = this.courses;
        //  this.filteredCourses = this.courses;
        this.coursesFilteredByCategory = this.courses.filter((course) => {
            return course.IsForVerein === true;
        });

        this.filteredCourses = [...this.coursesFilteredByCategory];
      }
      else 
      {
          this.coursesFilteredByCategory = this.courses.filter((course) => {
              return course.category === this.currentCategory;
          });

          this.filteredCourses = [...this.coursesFilteredByCategory];

      }

      // Re-filter by search term
      this.filterCoursesByTerm();
  }

  filterCoursesByTerm()
  {
      const searchTerm = this.searchTerm.toLowerCase();

      // Search
      if ( searchTerm === '' )
      {
          this.filteredCourses = this.coursesFilteredByCategory;
      }
      else
      {
          this.filteredCourses = this.coursesFilteredByCategory.filter((course) => {
              return course.Bezeichnung.toLowerCase().includes(searchTerm);
          });
      }
  }

  ngOnDestroy()
  {
    /*   this.categoriesSubscription.unsubscribe();
      this.coursesSubscription.unsubscribe(); */
  }


}
