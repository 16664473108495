import { User } from '../../shared/models/user.model';
import { UserActions, ActionTypes } from './user.actions';
import { Verein } from '../../shared/models/verein.model';
import { UserStateDto } from 'cuntino-api-service-v1';
import { FirmaPersonenDto } from 'cuntino-api-service-v1/public-api';

export interface ReducerUserState {
    user: User;
    verein: Verein;
    userState: UserStateDto;
    mitglieder: FirmaPersonenDto[]
  }
   
  export const initialState: ReducerUserState = {
    user: null,
    verein: null,
    userState: null,
    mitglieder: null
  };

  export function userReducer(
    state = initialState,
    action: UserActions
  ): ReducerUserState {
    switch (action.type) {

     
        case ActionTypes.LoadUserFinished: {
          return {
            ...state,
            user: action.payload,
          };
        }
        case ActionTypes.LoadVereinFinished: {
            return {
              ...state,
              verein: action.payload,
            };
          }
      
          case ActionTypes.LoadUserAppStateFinished: {
            return {
              ...state,
              userState: action.payload,
            };
          }
          case ActionTypes.LoadMitgliederFinished: {
            return {
              ...state,
              mitglieder: action.payload,
            };
          }
     
        default:
          return state;
      }
  }