import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
 {
    path      : 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
   // canActivate: [UserGuard]
},
 {
  path      : 'training',
  loadChildren: () => import('./main/trainingsplaene/trainingsplaene.module').then(m => m.TrainingsplaeneModule),
 // canActivate: [AdminGuard]
}, 
// otherwise redirect to home
  { path: '', redirectTo: 'training/plans', pathMatch: 'full'}    
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
