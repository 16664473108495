
import { Component, OnInit } from '@angular/core';
//import { ExerciseService , Exercise} from 'tt-api-service';
import { Guid } from 'guid-typescript';
import { AuthService } from 'auth-me-service';


@Component({
  selector: 'ttc-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  //public exercise : Exercise
  constructor(private authService: AuthService, 
   // private ttservice: ExerciseService
    ) { 
  //  ttservice.getExercise(Guid.parse("217FE27E-1597-4D07-9E16-9EB3E96EF8A5")).subscribe( data => this.exercise = data);
  }
  auth = window.localStorage.getItem('access_token');

  ngOnInit() {
  }

  
}
