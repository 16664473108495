import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ExerciseService, TrainingService } from 'ttc-api-service';

@Component({
  selector: 'ttc-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  public trainingId: string ="";
  public planId: string ="";
  
  public width: string = "640px";
  public height: string = "360px";

  exercise: any;
  public exerciseOverviews: any[];

  courseStepContent: any;
  currentStep: number;

  animationDirection: 'left' | 'right' | 'none' = 'none';
  @ViewChildren(FusePerfectScrollbarDirective) fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;
  constructor(private _exerciseService: ExerciseService, 
    private _trainingService: TrainingService,
    private _changeDetectorRef: ChangeDetectorRef,private route: ActivatedRoute,
    private _fuseSidebarService: FuseSidebarService) {
         // Set the defaults
         this.animationDirection = 'left';
         this.currentStep = 0;
 
         // Set the private defaults
     
     }

  ngOnInit()
  {
      // Subscribe to courses
      this.trainingId = this.route.snapshot.paramMap.get('id');
      this.planId = this.route.snapshot.paramMap.get('tpId');
      console.log("Training with ID:", this.trainingId);
      if(isNullOrUndefined(this.trainingId) || isNullOrEmptyString(this.trainingId)) return;

      this.getFirstExercise();
      this.getExerciseList();
      /*     let query = this.plaeneService.entity(this.planId);
          query.select (["Bezeichnung","id","Trainingsziel"]); 
          query.get().subscribe(([plan,odata]) => {
                  this.plan = plan;
              }); */
  }

  getFirstExercise()
    {

      this._trainingService.firstExercise(this.trainingId)
      .subscribe(([data,odata]) => this.exercise = data )
     
    }

/*     getExercise(exerciseId): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this.http.get("https://localhost:44327/odata/exercise/"+exerciseId)
                .subscribe((response: any) => {
                    this.onExerciseChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
 */
    getExerciseList()
    {
      this._trainingService.exerciseOverview(this.trainingId)
      .subscribe(([data,odata]) => this.exerciseOverviews = data )
      
    }

    gotoStep(step): void
    {
        // Decide the animation direction
        this.animationDirection = this.currentStep < step ? 'left' : 'right';

        // Run change detection so the change0176 191 257 50
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Set the current step
        this.currentStep = step;

      
     //   this.exercise = this._exerciseService.getExercise(this.exerciseOverviews[step].id);

    }

    /**
     * Go to next step
     */
    gotoNextStep(): void
    {
        if ( this.currentStep === this.exerciseOverviews.length - 1 )
        {
            return;
        }

        // Set the animation direction
        this.animationDirection = 'left';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Increase the current step
        this.currentStep++;
      //  this.exercise = this._exerciseService.getExercise(this.exerciseOverviews[this.currentStep].id);

    }

    /**
     * Go to previous step
     */
    gotoPreviousStep(): void
    {
        if ( this.currentStep === 0 )
        {
            return;
        }

        // Set the animation direction
        this.animationDirection = 'right';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Decrease the current step
        this.currentStep--;
    //    this.exercise = this._exerciseService.getExercise(this.exerciseOverviews[this.currentStep].id);
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    ngAfterViewInit(): void
    {
        this.courseStepContent = this.fuseScrollbarDirectives.find((fuseScrollbarDirective) => {
            return fuseScrollbarDirective.elementRef.nativeElement.id === 'course-step-content';
        });
    }
}
