import { Component, OnInit, ViewEncapsulation, ViewChildren, QueryList, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { Subscription } from 'rxjs';
import { TrainingplansService } from 'ttc-api-service';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';

@Component({
  selector: 'ttc-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class DetailsComponent implements  OnInit, OnDestroy, AfterViewInit {

  planId: string ="";
  plan: any;
  courseSubscription: Subscription;
  currentStep: number;
  courseStepContent : any;
  animationDirection: 'left' | 'right' | 'none' = 'none';
  @ViewChildren(FusePerfectScrollbarDirective) fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

  constructor(
      private plaeneService:   TrainingplansService,
      private changeDetectorRef: ChangeDetectorRef,
      private route: ActivatedRoute
  )
  {

  }

  ngOnInit()
  {
      // Subscribe to courses
      this.planId = this.route.snapshot.paramMap.get('id');
      console.log("Plandetails with ID:", this.planId);
      if(isNullOrUndefined(this.planId) || isNullOrEmptyString(this.planId)) return;

          let query = this.plaeneService.entity(this.planId);
          query.select (["Bezeichnung","id","Trainingsziel"]); 
          query.get().subscribe(([plan,odata]) => {
                  this.plan = plan;
              });
  }

  ngAfterViewInit(): void
  {
      this.courseStepContent = this.fuseScrollbarDirectives.find((fuseScrollbarDirective) => {
          return fuseScrollbarDirective.elementRef.nativeElement.id === 'course-step-content';
      });
  }

  ngOnDestroy(): void
  {
     // this.courseSubscription.unsubscribe();
  }

  gotoStep(step)
  {
      // Decide the animation direction
      this.animationDirection = this.currentStep < step ? 'left' : 'right';

      // Run change detection so the change
      // in the animation direction registered
      this.changeDetectorRef.detectChanges();

      // Set the current step
      this.currentStep = step;
  }

  gotoNextStep()
  {
      if ( this.currentStep === this.plan.totalSteps - 1 )
      {
          return;
      }

      // Set the animation direction
      this.animationDirection = 'left';

      // Run change detection so the change
      // in the animation direction registered
      this.changeDetectorRef.detectChanges();

      // Increase the current step
      this.currentStep++;
  }

  gotoPreviousStep()
  {
      if ( this.currentStep === 0 )
      {
          return;
      }

      // Set the animation direction
      this.animationDirection = 'right';

      // Run change detection so the change
      // in the animation direction registered
      this.changeDetectorRef.detectChanges();

      // Decrease the current step
      this.currentStep--;
  }

}
