import { Action } from '@ngrx/store';
import { User } from '../../shared/models/user.model';
import { Verein } from '../../shared/models/verein.model';
import { UserStateDto, FirmaPersonenDto } from 'cuntino-api-service-v1';


export enum ActionTypes {
    LoadUser = '[User] Load User',
    LoadUserFinished = '[User] Load User Finished',
    LoadVerein = '[Verein] Load Verein',
    LoadVereinFinished = '[Verein] Load Verein Finished',
    LoadMitglieder = '[Verein] Load Mitglieder',
    LoadMitgliederFinished = '[Verein] Load Mitglieder Finished',
    LoadUserAppState = '[UserAppState] Load UserAppState',
    LoadUserAppStateFinished = '[UserAppState] Load UserAppState Finished',

  }

  export class LoadUserAction implements Action {
    readonly type = ActionTypes.LoadUser;
  }
   
  export class LoadUserFinishedAction implements Action {
    readonly type = ActionTypes.LoadUserFinished;
    constructor(public payload: User) {}
  }

  export class LoadVereinAction implements Action {
    readonly type = ActionTypes.LoadVerein;
  }
   
  export class LoadVereinFinishedAction implements Action {
    readonly type = ActionTypes.LoadVereinFinished;
    constructor(public payload: Verein) {}
  }

  export class LoadMitgliederAction implements Action {
    readonly type = ActionTypes.LoadMitglieder;
  }
   
  export class LoadMitgliederFinishedAction implements Action {
    readonly type = ActionTypes.LoadMitgliederFinished;
    constructor(public payload: FirmaPersonenDto[]) {}
  }

  export class LoadUserAppStateAction implements Action {
    readonly type = ActionTypes.LoadUserAppState;
  }
   
  export class LoadUserAppStateFinishedAction implements Action {
    readonly type = ActionTypes.LoadUserAppStateFinished;
    constructor(public payload: UserStateDto) {}
  }

  export type UserActions =
  | LoadUserAction
  | LoadUserFinishedAction
  | LoadVereinAction
  | LoadVereinFinishedAction
  | LoadMitgliederAction
  | LoadMitgliederFinishedAction
  | LoadUserAppStateAction
  | LoadUserAppStateFinishedAction;