import { Component, OnInit, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { ExerciseService ,ExerciseUbeService} from 'ttc-api-service';
import { fuseAnimations } from '@fuse/animations';


@Component({
  selector: 'ttc-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
  animations   : fuseAnimations
})
export class ExerciseComponent implements OnInit , OnChanges{
@Input("exerciseId") exerciseId : string="";
@Input("exercise") exercise : any = undefined;

public exerciseMedien : any = undefined;

id = 'qDuKsiwS5xw';

playerVars = {
  cc_lang_pref: 'de'
};

private player;
private ytEvent;

public  scrHeight:number;
public scrWidth:number;
public widthSV = '100%';
public width: string = "640px";
public height: string = "360px";

@HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        if(this.scrWidth  < 640 ){
            this.width = ""+this.scrWidth+"px";
            this.height = ""+ this.scrWidth /1.7777 +"px"
            console.log(this.scrHeight, this.scrWidth);
            console.log(this.height, this.width);
        }
      
  }
  
  constructor(private exerciseService: ExerciseUbeService,private exerciseMediaService: ExerciseService) {
    this.getScreenSize();
   }


  ngOnChanges(changes: SimpleChanges): void {
   if(this.exerciseId !== this.exercise.Id){
     this.loadExercise();
     this.loadExerciseMedien();
   }
  }

  ngOnInit(): void {
  }

  public loadExercise(){
    this.exerciseService.entity(this.exerciseId)
    .get().subscribe(([plan,odata]) => {
      this.exercise = plan;
  });

  }
  public loadExerciseMedien(){
    this.exerciseMediaService.entity(this.exerciseId)
    .get().subscribe(([plan,odata]) => {
      this.exerciseMedien = plan;
  });
}

  savePlayer(player) {
    this.player = player;
    console.log('player instance', player);
  }
  onStateChange(event) {
    console.log('player state', event.data);
  }
  playVideo() {
    this.player.playVideo();
  }
  
  pauseVideo() {
    this.player.pauseVideo();
  }
}
