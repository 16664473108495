import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { AuthCallbackComponent } from 'auth-me-service';
import { MeinTrainingComponent } from './mein-training/mein-training.component';


const routes: Routes = [
  {
      path     : 'dashboard',
      component: IndexComponent
      
  },{
    path     : 'dashboard/mein-training',
    component: MeinTrainingComponent
    
},{
    path     : 'dashboard/mein-training/:id',
    component: MeinTrainingComponent
    
},
  {
    path     : 'auth-callback',
    component: AuthCallbackComponent
    
}
];
@NgModule({
 
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
