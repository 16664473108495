import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingsplaeneComponent } from './trainingsplaene.component';
import { DetailsComponent } from './details/details.component';
import { TrainingsplaeneRoutingModule } from './trainingsplaene-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSharedModule } from '@fuse/shared.module';
import { TrainingComponent } from './training/training.component';
import { FuseSidebarModule } from '@fuse/components';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { ExercisesModule } from '../exercises/exercises.module';


@NgModule({
  declarations: [TrainingsplaeneComponent, DetailsComponent, TrainingComponent],
  imports: [
    CommonModule,
    TrainingsplaeneRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,

    FlexLayoutModule,
    ScrollViewModule,
    FuseSharedModule,
    FuseSidebarModule,
    ExercisesModule
  ]
})
export class TrainingsplaeneModule { }
