import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  /*   {
        id       : 'applications',
        title    : 'TT-Coach',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'dashboards',
                title    : 'Dashboard',
                translate: 'NAV.DASHBOARDS',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'overview',
                        title: 'Übersicht',
                        type : 'item',
                        url  : '/dashboard/mein-training'
                    }
                   ,
                    {
                        id   : 'analytics',
                        title: 'Leistung',
                        type : 'item',
                        url  : '/main/dashboard'
                    }
                ]
            },
            {
                id       : 'calendar',
                title    : 'Kalender',
                translate: 'NAV.CALENDAR',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/calendar'
            },
            {
                id       : 'file-manager',
                title    : 'Unterlagen',
                translate: 'NAV.FILE_MANAGER',
                type     : 'item',
                icon     : 'folder',
                url      : '/apps/file-manager'
            },
            {
                id       : 'contacts',
                title    : 'Kontakte',
                translate: 'NAV.CONTACTS',
                type     : 'item',
                icon     : 'account_box',
                url      : '/apps/contacts'
            }
        ]
    }, */
    {
        id      : 'training',
        title   : 'Training',
        // translate: 'NAV.TRAINING',
        type    : 'group',
        icon    : 'school',
        children: [
            {
                id      : 'mytraining',
                title   : 'Mein Training',
                type    : 'item',
                icon    : 'lock',
                url  : '/dashboard/mein-training'
                
            },
            {
                id   : 'trainingplans',
                title: 'Trainingspläne',
                type : 'item',
                icon : 'alarm',
                url  : '/training/plans'
            },
         /*    {
                id      : 'exercises',
                title   : 'Übungen',
                type    : 'collapsable',
                icon    : 'error',
                children: [
                    {
                        id   : 'exercise',
                        title: 'Übungsübersicht',
                        type : 'item',
                        url  : '/exercise/home'
                    },
                    {
                        id   : 'add-exercise',
                        title: 'Übung anlegen',
                        type : 'item',
                        url  : '/exercise/add'
                    }
                ]
            } */
        ]
  
    }
];
