import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExercisesRoutingModule } from './exercises-routing.module';
import { ExerciseComponent } from './exercise/exercise.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

@NgModule({
  declarations: [ExerciseComponent],
  imports: [
    CommonModule,
    ExercisesRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    NgxYoutubePlayerModule.forRoot(),
ScrollViewModule,
    FlexLayoutModule,
   
    FuseSharedModule,
    FuseSidebarModule,
  ],exports:[ExerciseComponent]
})
export class ExercisesModule { }
