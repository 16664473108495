import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { ReducerUserState, userReducer } from './user.reducer';
import { User } from '../../shared/models/user.model';
 
export const featureStateName = 'userFeature';
 
export interface UserState {
  user: ReducerUserState;
}
 
export const userReducers: ActionReducerMap<UserState> = {
  user: userReducer,
};
 
// extract the main property 'todoFeature' from the state object
export const getUserFeatureState = createFeatureSelector<UserState>(
  featureStateName
);

export const getUser = createSelector(
    getUserFeatureState,
    (state: UserState) => state.user.user
  );

  export const getVerein = createSelector(
    getUserFeatureState,
    (state: UserState) => state.user.verein
  );
  export const getMitglieder= createSelector(
    getUserFeatureState,
    (state: UserState) => state.user.mitglieder
  );

  export const getUserAppState= createSelector(
    getUserFeatureState,
    (state: UserState) => state.user.userState
  );

