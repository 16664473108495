import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { TrainingplanConstractionService } from 'ttc-api-service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ttc-mein-training',
  templateUrl: './mein-training.component.html',
  styleUrls: ['./mein-training.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class MeinTrainingComponent implements  OnInit,AfterViewInit {

  private planId: string ="250AD2BB-B833-4CEB-B778-CECFBD65684D";
  trainings: any[];
  training: any;
 

  public weekOverviews: any[] = [];
  weekContent: any;
  weekStep: number;
  animationDirection: 'left' | 'right' | 'none' = 'none';
  @ViewChildren(FusePerfectScrollbarDirective) fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

  constructor( private route: ActivatedRoute, 
    private datePipe: DatePipe,
   // private _meintrainingService: MeintrainingService, 
    private trainingplanConstractionService: TrainingplanConstractionService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseSidebarService: FuseSidebarService) { 
   ;
    }

  ngOnInit()
  {
      // Subscribe to courses
      
      if(!isNullOrEmptyString(this.route.snapshot.paramMap.get('id')))
        this.planId = this.route.snapshot.paramMap.get('id');

      console.log("mein-training with ID:", this.planId);
      if(isNullOrUndefined(this.planId) || isNullOrEmptyString(this.planId)) return;
      this.getWeeks(this.planId)
      this.getTraining(this.planId);
     
  }

  getTraining(trainingId)
  {
    let query = this.trainingplanConstractionService.entities();
    query.select (["WochenTagReihenfolge","id","TrainingsWoche"]); 
    query.expand("training($select=Id,Bezeichnung,Beschreibung,Trainingsziel),TrainingPlan($select=ImageUrl)");
    query.filter({TrainingPlanId: trainingId});
    query.get().subscribe(([data,odata]) => {
            this.trainings = data;
        }); 
  }

  getWeeks(trainingId)
  {
    let query = this.trainingplanConstractionService.weeks(trainingId);
  
    query.subscribe(([data,odata]) => {
            this.weekOverviews = data;
        }); 

  }
  public filterTrainingForWeak(weak : number) : any[]{
    return this.trainings.filter(f => f.TrainingsWoche === weak);
  }

  public getTabName(weak: number , isAbo: boolean): string{
    let name = "Woche ";
  
    let weekToday = this.datePipe.transform(Date.now(),"w");
      if(+weekToday === weak && isAbo)
        name = "Diese Woche";
    else if( +weekToday === weak-1 && isAbo){
      name = "Nächste Woche";
    } else if( +weekToday === weak+1 && isAbo){
      name = "Letzte Woche";
    }else{
      name = name + weak;
    }

   
    return name;
  }

  ngAfterViewInit(): void
    {
        this.weekContent = this.fuseScrollbarDirectives.find((fuseScrollbarDirective) => {
            return fuseScrollbarDirective.elementRef.nativeElement.id === 'week-step-content';
        });
    }
    gotoStep(step): void
    {
        // Decide the animation direction
        this.animationDirection = this.weekStep < step ? 'left' : 'right';

        // Run change detection so the change0176 191 257 50
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Set the current step
        this.weekStep = step;

      
        //this.training = this._meintrainingService.getTraining(this.weekOverviews[step].id);
       
    }

    /**
     * Go to next step
     */
    gotoNextStep(): void
    {
        if ( this.weekStep === this.weekOverviews.length - 1 )
        {
            return;
        }

        // Set the animation direction
        this.animationDirection = 'left';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Increase the current step
        this.weekStep++;
       // this.training = this._meintrainingService.getTraining(this.weekOverviews[this.weekStep].id);

    }

    /**
     * Go to previous step
     */
    gotoPreviousStep(): void
    {
        if ( this.weekStep === 0 )
        {
            return;
        }

        // Set the animation direction
        this.animationDirection = 'right';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Decrease the current step
        this.weekStep--;
     //   this.training = this._meintrainingService.getWeeks(this.weekOverviews[this.weekStep].id);
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

}
