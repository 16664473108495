import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { fuseConfig } from './configs/fuse-config';
import { environment} from '../environments/environment';

import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { DashboardModule} from './main/dashboard/dashboard.module';
//import { TtApiServiceModule } from 'tt-api-service';

import { VerticalLayout1Module } from '@fuseapp/layout/vertical/layout-1/layout-1.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TTCoachConfigService } from './configs/config.service';
import { ODataModule   } from 'angular-odata';
import { throwError } from 'rxjs';
import { AuthOidcAccountModule, AuthOidcHttpInterceptor, ErrorInterceptor } from 'auth-me-service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { IntlModule } from '@progress/kendo-angular-intl';
import { HttpModule } from '@angular/http';
import { registerLocaleData } from '@angular/common';
import  localeDe   from '@angular/common/locales/de';
import { NotificationService } from '@progress/kendo-angular-notification';

import localeDeCaExtra from '@angular/common/locales/extra/de';
import { TrainingsplaeneModule } from './main/trainingsplaene/trainingsplaene.module';
import { TrainingplansService,TrainingplanConstractionService,ExerciseService,ExerciseUbeService ,TrainingService } from 'ttc-api-service';
import { TrainingModule } from './main/training/training.module';
import { ExercisesModule } from './main/exercises/exercises.module';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { OidcConfigService } from 'angular-auth-oidc-client';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';




registerLocaleData(localeDe,localeDeCaExtra);
@NgModule({
  declarations: [
    AppComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,IntlModule ,
    BrowserAnimationsModule,

    HttpModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    FlexLayoutModule,
    FuseSharedModule,
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSidebarModule,
    VerticalLayout1Module,
    MatMomentDateModule,
     // Material
     MatButtonModule,
     MatIconModule,
     AuthOidcAccountModule,
     // STORE
     StoreModule.forRoot({}),
     EffectsModule.forRoot([]),

     ScrollViewModule,
   //TTCOACHMODULE
   DashboardModule,
   TrainingsplaeneModule,
   TrainingModule,
   ExercisesModule,
   //TtApiServiceModule,
  
    ODataModule.forRoot({
      baseUrl: environment.rest_service_url,     
      metadataUrl:environment.rest_service_url+'/$metadata',
      withCredentials: false,
      errorHandler: (error: HttpErrorResponse) => {
        return throwError(error);
      }
    }),
  ],
  providers: [OidcConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthOidcHttpInterceptor,
      multi: true
    },  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "de-DE" },
  NotificationService,
  ExerciseService,
  ExerciseUbeService,
  TrainingService,
  TrainingplansService,
  TrainingplanConstractionService 
],
  bootstrap: [AppComponent]
})
export class AppModule { }

